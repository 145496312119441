import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import SVG from "./SVG"
import "./MainFooter.scss"
import SubscribeFooter from "./SubscribeFooter"
import ButtonCTA from "./ButtonCTA"

export default props => (
  /**
   * Gets all the data for the footer from AgilityCMS
   */

  <StaticQuery
    query={graphql`
      query FooterQuery {
        allAgilitySitemapNode {
          nodes {
            languageCode
            path
            menuText
            pageID
            visible {
              menu
            }
          }
        }
        agilityGlobalFooter(
          properties: { referenceName: { eq: "globalfooter" } }
        ) {
          customFields {
            contactNumberOne
            contactNumberTwo
            featuredEmailAddress
            subscribeFormLabel
            subscribeSubmitButtonText
            subscribeTitle
            facebookUrl {
              href
              target
              text
            }
            instagramUrl {
              href
              target
              text
            }
            linkedInUrl {
              href
              target
              text
            }
            youtubeUrl {
              href
              target
              text
            }
          }
        }
      }
    `}
    render={queryData => {
      const viewModel = {
        customFields: queryData.agilityGlobalFooter.customFields,
        route: props.route,
        menuLinks: queryData.allAgilitySitemapNode.nodes.filter(sitemapNode => {
          let isTopLevelPage = sitemapNode.path.split("/").length === 2
          const isThisLanguage = sitemapNode.languageCode === props.languageCode
          if (sitemapNode.path === "/home") sitemapNode.path = "/"
          if (props.isMultiLanguage) {
            isTopLevelPage = sitemapNode.path.split("/").length === 3
          }
          return isThisLanguage && isTopLevelPage && sitemapNode.visible.menu
        }),
      }
      return <MainFooter {...viewModel} />
    }}
  />
)

/**
 * Component to render the footer.
 * @function MainFooter
 */

const MainFooter = props => {
  const customFields = props.customFields
  const subscribeFormData = {
    label: customFields.subscribeFormLabel,
    btnTxt: customFields.subscribeSubmitButtonText,
  }

  /**
   * Loops trough the links and returns an array of JSX elements.
   * @function renderLinks
   */
  const renderLinks = () => {
    const links = []

    props.menuLinks.forEach(node => {
      links.push(
        <li
          className={`nav-links__item ${
            node.path === props.route.pathname ? "active" : ""
          }`}
          key={node.pageID}
        >
          <Link to={node.path}>{node.menuText}</Link>
        </li>
      )
    })

    links.push(
      <li
        key={"sitemap"}
        className={`nav-links__item ${
          "/sitemap" === props.route.pathname ? "active" : ""
        }`}
      >
        <Link to={"/sitemap"}>Sitemap</Link>
      </li>
    )
    links.push(
      <li
        key={"privacy-policy"}
        className={`nav-links__item ${
          "/privacy-policy" === props.route.pathname ? "active" : ""
        }`}
      >
        <Link to={"/privacy-policy"}>Privacy Policy</Link>
      </li>
    )
    return links
  }

  /**
   * Footer  JS rendering.
   */

  return (
    <footer className="main-footer">
      <div className="container-fluid p-0">
        <div className="row justify-content-sm-start m-0">
          <div className="col-12 col-md-8 d-flex flex-column mb-sm-5 p-0">
            <p className="subscribe-title order-sm-1 order-2 mb-sm-4">
              {customFields.subscribeTitle}
            </p>
            <div className="subscribe-wrapper order-3 order-sm-2 mb-sm-4">
              <SubscribeFooter {...subscribeFormData} />
            </div>
          </div>
        </div>
        <hr className="line"></hr>
        <div className="row ">
          <div
            className="col-md-6 col-12 order-md-first order-last justify-content-center d-flex d-md-inline flex-column"
            style={{ paddingTop: "200px" }}
          >
            <span className="social-links-title">JOIN OUR COMMUNITY</span>
            <ul className="social-links mt-sm-4 d-flex">
              <li className="social-links__item mr-3">
                <a
                  href={customFields.linkedInUrl.href}
                  rel="noopener"
                  target={customFields.linkedInUrl.target}
                  aria-label="Pixel506 LinkedIn page (Opens a new Window)."
                >
                  <SVG
                    fill={"#FFF"}
                    name={"linked-in"}
                    width={40}
                    height={40}
                  ></SVG>
                </a>
              </li>
              <li className="social-links__item mr-3">
                <a
                  href={customFields.instagramUrl.href}
                  rel="noopener"
                  target={customFields.instagramUrl.target}
                  aria-label="Pixel506 Instagram page (Opens a new Window)."
                >
                  <SVG
                    fill={"#FFF"}
                    name={"instagram"}
                    width={40}
                    height={40}
                  ></SVG>
                </a>
              </li>
              <li className="social-links__item mr-3">
                <a
                  href={customFields.facebookUrl.href}
                  rel="noopener"
                  target={customFields.facebookUrl.target}
                  aria-label="Pixel506 Facebook page (Opens a new Window)."
                >
                  <SVG
                    fill={"#FFF"}
                    name={"facebook"}
                    width={40}
                    height={40}
                  ></SVG>
                </a>
              </li>
            </ul>
            <ButtonCTA
              data={{
                text: "Contact Us  →",
                styles: "footer-cta",
                href: "/contact-us",
              }}
            />
          </div>
          <div className="col-md-2 col-6" style={{ paddingTop: "200px" }}>
            <span className="contact-title">Sitemap</span>
            <nav
              aria-label="Footer navigation."
              className="d-md-flex col-lg-11 mb-sm-5 p-0"
            >
              <ul className="nav-links w-100 d-flex flex-column justify-content-between">
                {renderLinks()}
              </ul>
            </nav>
          </div>
          <div className="col-md-4 col-6" style={{ paddingTop: "200px" }}>
            <span className="contact-title">Contact</span>
            <p className="info-details">{customFields.featuredEmailAddress}</p>
            {/* <p className="info-details">{customFields.contactNumberOne}</p> */}
            {/* <p className="info-details">{customFields.contactNumberTwo}</p> */}
            <p className="copyright d-none d-md-flex">
              &#169; PIXEL506, LLC. ALL RIGHTS RESERVED
            </p>
          </div>
          <div className="col-12 d-md-none order-last">
            <p className="copyright">
              &#169; PIXEL506, LLC. ALL RIGHTS RESERVED
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
